import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function ONas() {
  const seo = {
    title: "O nas - wszystko co chcesz wiedzieć o Medicus Dukla",
    description: "Od stycznia 2003 roku świadczymy usługi medyczne dla naszych pacjentów. W tym okresie przeszliśmy drogę rozwoju poszerzając zakres usług.",
    url: "https://medicusdukla.pl/o-nas"
  }
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section className="o-about" data-g="container">
        <div data-g="grid">
          <div data-g="4 6@md">
            <h1 className="a-heading-medium">O nas</h1>
            <p>
              Od stycznia 2003 roku świadczymy usługi medyczne dla naszych pacjentów. W tym okresie przeszliśmy drogę
              rozwoju poszerzając systematycznie zakres świadczonych usług. Od lipca 2014 działa poradnia
              stomatologiczna i hospicjum domowe, od grudnia uruchamiamy transport sanitarny własnym ambulansem, a w
              przyszłości planujemy poszerzyć usługi specjalistyczne.
              <br/><br/>
            </p>
          </div>
        </div>
        <div data-g="grid">
          <div className={`m-doctor-card`} data-g="4 6@md">
            <div className="m-doctor-card__initials">
              <span>RK</span>
            </div>
            <div className="m-doctor-card__role">SPECJALISTA CHORÓB DZIECIĘCYCH</div>
            <h4 className="m-doctor-card__name">lek. Renata Krajmas</h4>
            <p>
              Absolwentka AM w Lublinie w latach 1985-1991. Posiada specjalizacje II stopnia z zakresu Chorób Dziecięcych. Pracowała w takich placówkach jak Szpital w Krośnie - Oddział Dziecięcy, Ośrodek Zdrowia w Dukli - Poradnia Pediatryczna i Higiena Szkolna w latach 1994-2003. Od 2003 jest prezesem spółki Medicus-Dukla sp. z o.o. Dodatkowo od 2012 roku pracuje w Poradni Psychologiczno-Pedagogicznej w Miejscu Piastowym.
            </p>
          </div>
          <div className={`m-doctor-card`} data-g="4 6@md">
            <div className="m-doctor-card__initials">
              <span>KK</span>
            </div>
            <div className="m-doctor-card__role">internista, lekarz rodzinny</div>
            <h4 className="m-doctor-card__name">lek. Kazimierz Krajmas</h4>
            <p>Absolwent Akademii Medycznej w Lublinie w latach 1985-1991, internista i specjalista medycyny rodzinnej. Posiada uprawnienia do badania kierowców i przeprowadzania badań profilaktycznych pracowników. Ukończył kursy badania USG - jamy brzusznej i tarczycy. Pracował w takich placówkach jak Szpital w Krośnie, Pogotowie Ratunkowe w Krośnie, Ośrodek Zdrowia Tylawa oraz SPG ZOZ Dukla gdzie pełnił funkcje dyrektora. W 2002 założył przychodnie "Medicus-Dukla" sp. z o.o. której obecnie jest współwłaścicielem.</p>
          </div>
          <br/><br/>
        </div>
        <div data-g="grid">
          <div data-g="4 6@md">

            <h2 className="a-heading-medium">Zabytkowy budynek</h2>
            <p>
              Siedzibą Medicus-Dukla Sp. z o.o. NZOZ w Dukli jest budynek, którego początki sięgają XVIII w. Jak wynika z przekazu Ewarysta Andrzeja hr. Kuropatnickiego, właściciela niedalekiego Tarnowca, już przed 1786 r. funkcjonował w tym miejscu urząd pocztowy. Jego powstanie wynikało z faktu, iż Dukla w 1774 r. została stolicą cyrkułu w dystrykcie pilźnieńskim, a rok później wyszedł austriacki patent pocztowy o organizacji urzędów pocztowych (pierwotnie urząd cyrkularny i towarzyszące mu służby mieściły się w kompleksie pałacowym).
            </p>
            <p>
              Budynek kształtem przypominał literę V, którego jeden bok frontem zwrócony był do pałacu, drugi równoległy do potoku Teodorówka (obecnie Dukielka). W XIX w. pożary zniszczyły w dużym stopniu zabudowę Dukli, w tym również urząd pocztowy. Na mapie katastralnej z 1851 r. jest już widoczny nowy gmach na rogu ulicy Jasielskiej (obecnie róg ulic Trakt Węgierski i Żwirki i Wigury) wybudowany staraniem Męcińskich. Po wielu przebudowach w ogólnym kształcie przetrwał do czasów obecnych. W okresie międzywojennym w budynku od strony ul. Żwirki i Wigury na parterze mieścił się Udziałowy Bank Spółdzielczy w Dukli, którego dyrektorem był do 1939 r. Seweryn Gołębiowski, a przewodniczącym Rady Nadzorczej ówczesny burmistrz Dukli dr Jan Strycharski. Na piętrze mieszkali lokatorzy. W miejscu obecnego wejścia do apteki mieściła się restauracja Karola Skwarka z wyszynkiem, bilardem i in. rozrywkami.
            </p>
            <p>
              Od strony Traktu był zlokalizowany komisariat państwowej policji i mieszkał tam komendant policji Płocicki. Na drugim piętrze znajdował się klub inteligencji dukielskiej, powszechnie zwany kasynem i sala widowiskowa. W kasynie urządzane były przedstawienia teatralne dla szerokiego kręgu publiczności. Kierownikiem kasyna i reżyserem tych przedstawień był August hr. Łoś. W suterenach mieszkali pracownicy fizyczni, fornale i inni pracownicy dworscy. Po drugiej wojnie światowej budynek ten z nr 112, stanowiący wcześniej własność Stanisława Męcińskiego -Tarnowskiego został przejęty przez Skarb Państwa i usytuowano w nim Ośrodek Zdrowia i lokatorów, m. in. doktora Jacka Jurasza. Obecnie nadal pełni podobne funkcje.
            </p>
          </div>
          <div data-g="4 6@md">
            <StaticImage className="a-img border" src="../images/about/budynek 1.png" alt="budynek" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>
        <div  className="u-text-center">
          <br /><br /><br /><br />
          <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz"/>
          <br /><br />
          <StaticImage className="a-img" src="../images/homepage/loga.png"
                       alt="fundusze europejskie, rzeczpospolita polska, podkarpackie, unia europejska" quality={80} formats={['auto', 'webp', 'avif']}/>
        </div>
      </section>
    </Layout>
  )
}
